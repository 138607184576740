* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

input:focus, textarea:focus {
  outline:none;
}

*:focus, *:active, * { outline:0 !important; -webkit-tap-highlight-color: transparent;}


@font-face {
  font-family: TitilliumWeb-Regular;
  src: url("./assets/fonts/TitilliumWeb-Regular.ttf");
}

body {
  margin: 0;
  background-color: #ff5b00;
  font-family: TitilliumWeb-Regular;
}