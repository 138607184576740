.ecc-status-view-home {
  
}

.ecc-status-view-home-box {
  background: #FFFFFF;
  position: absolute;
  top: 40px;
  bottom: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px #b54000;
  overflow: hidden;
  left: 60px;
  right: 60px;
}

.ecc-status-view-home-header {
  box-shadow: 0px 0px 15px #CCC;
}

.ecc-status-view-home-header-logo {
  width: 180px;
  height: 70px;
  background-size: 100%;
  display: block;
  margin: auto;
  background-image: url("./logo.png");
  background-position: center;
  background-repeat: no-repeat;
}

.ecc-status-view-home-content {
  position: absolute;
  top: 70px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ecc-status-view-home-items {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #ededed;
}

.ecc-status-view-home-items-title {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  line-height: 60px;
}

.ecc-status-view-home-items-list {

}

.ecc-status-view-home-item {
  background-size: 25px;
  background-repeat: no-repeat;
  height: 34px;
  border-bottom: 1px solid #ededed;
  background-position: 10px center;
  padding-left: 45px;
  position: relative;
  cursor: pointer;
}

.ecc-status-view-home-item-label {
  font-weight: bold;
  line-height: 31px;
  font-size: 13px;
}

.ecc-status-view-home-item-code {
  position: absolute;
  right: 10px;
  background: #000;
  color: #FFF;
  font-weight: bold;
  padding: 5px 8px;
  border-radius: 100px;
  font-size: 10px;
  top: 7px;
}

.ecc-status-view-home-item.success .ecc-status-view-home-item-code {
  background: green;
}

.ecc-status-view-home-item.error .ecc-status-view-home-item-code {
  background: red;
}

.ecc-status-view-home-item.success {
  background-image: url("./icon-success.svg");
}

.ecc-status-view-home-item.error {
  background-image: url("./icon-error.svg");
  background-size: 21px;
  background-position: 12px center;
}

@media screen and (max-width: 1300px) {
  .ecc-status-view-home-item-label {
    font-size: 10px;
  }
}

@media screen and (max-width: 1160px) {
  .ecc-status-view-home-items-title {
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    line-height: 35px;
    padding-left: 12px;
  }

  .ecc-status-view-home-items {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .ecc-status-view-home-item-label {
    font-size: 13px;
  }
}

.ecc-status-view-home-box-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(21, 21, 21, 0.8);
  z-index: 100;
}

.ecc-status-view-home-box-modal-box {
  width: 600px;
  top: 40px;
  bottom: 40px;
  position: absolute;
  overflow: hidden;
  background: #FFF;
  left: 50%;
  margin-left: -300px;
  border-radius: 20px;
}

.ecc-status-view-home-box-modal-header {
  width: 100%;
  height: 70px;
  box-shadow: 0px 0px 15px #ccc;
}

.ecc-status-view-home-box-modal-header-title {
  font-weight: bold;
  line-height: 70px;
  padding-left: 26px;
}

.ecc-status-view-home-box-modal-header-close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  background-image: url("./icon-close.svg");

  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;

}

.ecc-status-view-home-box-modal-body {
  padding: 15px;
  position: absolute;
  top: 70px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ecc-status-view-home-box-modal-body-label {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  color: #4a4a4a;
}

.ecc-status-view-home-box-modal-body-response {
  user-select: text;
  word-break: break-all;
  line-height: 20px;
  font-size: 11px;
  border: 2px solid #e9e9e9;
  padding: 10px;
  margin-bottom: 20px;
}

.ecc-status-view-home-box-modal-body img {
  width: 100%;
  border: 2px solid #e9e9e9;
}

@media screen and (max-width: 768px) {
  .ecc-status-view-home-box-modal-box {
    width: 100%;
    left: 0;
    margin-left: 0;
    top: 0;
    margin-top: 0;
    border-radius: 0;
    bottom: 0;
  }
  .ecc-status-view-home-box {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 0;
  }
}

/* @media screen and (max-width: 600px) {
  .ecc-status-view-home-box {
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}

@media screen and (max-width: 330px) {
  .ecc-status-view-home-box {
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
  }
} */