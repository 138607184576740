.rdp-react-template-component-header {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 3;
  background: #FFFFFF;
}

.rdp-react-template-component-header-logo {
  width: 120px;
  margin: 9px;
  cursor: pointer;
}